html, body {
  width: 100%;
}

body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  margin: 0;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ebebeb;
  position: relative;
  align-items: stretch;
}

#root {
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  align-items: stretch;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
